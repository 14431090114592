import baseService from './baseService';

export function addFile(body) {
  return baseService.post('/file', body);
}

export function uploadLanderImage(body) {
  return baseService.post('/file/landerImage', body);
}

export function moveFile(id, folder_id) {
  return baseService.put('/file/move', { id, folder_id });
}

export function editFileName(id, name) {
  return baseService.put('/file/rename', { id, name });
}

export function editVisibility(id, is_public) {
  return baseService.put('/file/visibility', { id, is_public });
}

export function getFile() {
  return baseService.get('/file');
}

export function getPublicFiles(folderID) {
  return baseService.get(`/file/public/${folderID}`);
}

export function getOnSiteFiles(folderID) {
  return baseService.get(`/file/site/${folderID}`);
}

export function deleteFile(id) {
  return baseService.delete(`/file/${id}`);
}

export function getSharedFiles(folderID) {
  return baseService.get(`/file/shared/${folderID}`);
}

export function getFilesBySearch(search) {
  return baseService.get(`/file/public/search/${search}`);
}
